<template>
  <main class="about">
    <Breadcrumb></Breadcrumb>
    <PageHeader
      title="Experience life, work, and culture at UGA"
      backgroundImage="faculty-staff/henry-young.jpg"
      backgroundColor="transparent"
      textColor="white"
      overlay="dark"
      align="center"
      size="lg"
    >
      <p>
        At the University of Georgia, we prepare our staff and faculty to
        improve their lives and in their communities. Your commitment to higher
        education is vital to our success.
      </p>
    </PageHeader>

    <section class="container-xl my-4">
      <ResourceList :resources="resources"></ResourceList>
    </section>
    <Section
      data-aos="fade-in"
      type="inset"
      imgSide="left"
      imgURL="faculty-staff/36333-068.jpg"
    >
      <h3>Powering your Professional Growth</h3>
      <p>
        UGA faculty and staff can take their professional development to the
        next level through our Professional Education Portal (PEP). Our newly
        revamped system boasts a full catalog of more than 400 learning
        opportunities for career development and research.
      </p>
      <Btn
        :href="`https://pep.uga.edu/`"
        color="primary"
        :outline="false"
        size="sm"
        >Explore Opportunites</Btn
      >
    </Section>
  </main>
</template>

<script>
// @ is an alias to /src
import Breadcrumb from '@/components/Breadcrumb.vue';
import ResourceList from '@/components/ResourceList.vue';
import PageHeader from '@/components/PageHeader.vue';
import Btn from '@/components/Btn.vue';
import Section from '@/components/Section.vue';

export default {
  name: 'Faculty and Staff',
  metaInfo: {
    title: 'Faculty and Staff Resources | University of Georgia',
    description: 'UGA prepares staff and faculty to improve their lives and their communities. Your commitment to higher education is vital to our success.',
    og: {
      image: '',
      title: '',
      description: '',
    },
  },
  data() {
    return {
      resources: [
        {
          name: 'Employment',
          link: 'https://hr.uga.edu/employees/employment/',
          content: 'Learn about and apply to vacant positions.',
        },
        {
          name: 'UGA Mail',
          link: 'https://ugamail.uga.edu/',
          content: 'Access UGA email and Office 365.',
        },
        {
          name: 'OneUSG Connect',
          link: 'https://hcm-sso.onehcm.usg.edu/',
          content: 'A system-wide employee portal for USG employees.',
        },
        {
          name: 'People Search Directory',
          link: 'https://peoplesearch.uga.edu/',
          content: 'Find UGA faculty, staff, and students.',
        },
        {
          name: 'Faculty Affairs',
          link: 'https://provost.uga.edu/faculty-affairs/',
          content: 'Information about faculty hiring, promotion and professional development',
        },
        {
          name: 'Registrar’s Office',
          link: 'https://reg.uga.edu/',
          content: 'Access Athena, Bulletin, and other course management tools.',
        },
        {
          name: 'Benefits',
          link: 'https://hr.uga.edu/employees/benefits/',
          content: 'Learn more about the health, welfare, and other benefits available to UGA employees.',
        },
        {
          name: 'MyUGA Portal',
          link: 'https://my.uga.edu/',
          content: 'The gateway to UGA-wide resources.',
        },
        {
          name: 'Policies',
          link: 'https://policies.uga.edu/',
          content: 'Learn more about UGA policies and procedures.',
        },
        {
          name: 'Payroll Department',
          link: 'http://busfin.uga.edu/payroll/',
          content: 'Access payroll links, forms, and policies.',
        },
        {
          name: 'Master Calendar',
          link: 'https://calendar.uga.edu/',
          content: 'See upcoming campus events.',
        },
        {
          name: 'Learning & Development',
          link: 'https://hr.uga.edu/employees/training/',
          content: 'Professional education and experiences that help the UGA community succeed and flourish in their work and lives.',
        },
      ],
    };
  },
  components: {
    Breadcrumb,
    ResourceList,
    PageHeader,
    Btn,
    Section,
  },
};
</script>

<style scoped lang="scss"></style>
